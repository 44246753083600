<template>
  <b-container class="pt-5 mb-4">
    <b-row class="mb-5">
      <b-col class="d-flex justify-content-between">
        <curva-title :title="$t('about.brandDetails')"/>
        <router-link :to="`/categories/all-categories?brand=${$route.params.id }`">
          <b-button class="curva-icon-btn">{{ $t('about.browse') }} </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-row><b-col>
      <b-card class="details-container iq-style4">
        <b-row>
          <b-col lg="2" class="text-center">
            <img class="w-100" :src="brandDetails.image"/>
            <h3>{{ brandDetails.name }}</h3>
          </b-col>
          <b-col lg="10">
            <b-row>
              <b-col lg="12">
                <h4 class="text-initial">{{ $t('about.about') + ' ' + brandDetails.name }}</h4>
                <p class="text-initial" v-html="brandDetails.description"></p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col></b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import aboutServices from '../services/about.services'
export default {
/*   props: {
    brandDetails: {
      default () {
        return {
          name: 'NIKE',
          img: require('@/assets/images/curva/top-brands/nike.png'),
          about: 'Nike, Inc. is an American sportswear manufacturer company located in Beaverton, Oregon, United States that was founded on 25 January 1964. The company was initially created under the name of “Blue Ribbon Sports” by Bill Bowerman and Phil Knight, before changing to its current name Nike on 30 May 1971.\n' +
              '\n' +
              'The company’s success spread quickly in the United States and the rest of the world thanks to their sponsorships with major athletes and sports clubs, such as the famous basketball player Michael Jordan, in addition to their unique wide variety of cleats. Nike’s logo, called “Swoosh” by the company, is also considered as one of the most recognized logos in the world.\n' +
              '\n' +
              'In the football scene, Nike supply football wear to many top-tier clubs in the world such as Atlético Madrid, Barcelona, Chelsea, Liverpool, Roma and many more. The company also supply kits to numerous national teams, including Brazil, England, France, Nigeria and Portugal.'
        }
      }
    }
  }, */
  data () {
    return {
      brandDetails: {}
    }
  },
  methods: {
    getBandDetails () {
      aboutServices.getSpacificBrand(this.$route.params.id).then(res => {
        this.brandDetails = res.data.data
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getBandDetails()
  }
}
</script>
